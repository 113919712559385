.progress-bar-container {
    margin: 20px 0;
    padding: 10px;
    text-align: center;
    font-family: Arial, sans-serif;
    color: #f1f1f1;
  }
  
  .progress-bar {
    background: #333;
    border-radius: 10px;
    overflow: hidden;
    height: 25px;
    width: 100%;
    max-width: 600px; /* Limit the width of the progress bar */
    margin: 0 auto;
  }
  
  .progress-bar-fill {
    background: linear-gradient(90deg, #4caf50, #81c784); /* Animated gradient */
    height: 100%;
    transition: width 0.5s ease; /* Smooth animation for progress updates */
  }
  
  .progress-bar-text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  