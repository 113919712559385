/* NFT Marketplace General Styling */
.marketplace {
  padding: 20px;
  color: #f1f1f1; /* Light text color */
  background-color: #121212; /* Dark background color */
  min-height: 100vh; /* Ensure it covers the full viewport */
}

/* Controls (Search, Filter, and Sort) */
.controls {
  display: flex;
  flex-wrap: wrap; /* Allows controls to wrap on smaller screens */
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar,
.sort-dropdown,
.filter-dropdown {
  flex: 1; /* Ensures all controls take equal space */
  min-width: 120px; /* Prevents controls from becoming too small */
  padding: 10px;
  font-size: 16px;
  background-color: #1f1f1f; /* Dark input background */
  border: 1px solid #333; /* Subtle border for inputs */
  color: #f1f1f1; /* Light text color */
  border-radius: 8px; /* Rounded corners for a polished look */
  outline: none; /* Remove default focus outline */
  transition: box-shadow 0.3s, border-color 0.3s; /* Smooth transitions */
}

.search-bar:focus,
.sort-dropdown:focus,
.filter-dropdown:focus {
  border-color: #007bff; /* Highlighted border color */
  box-shadow: 0 0 5px #007bff; /* Glowing effect */
}

.search-bar:hover,
.sort-dropdown:hover,
.filter-dropdown:hover {
  border-color: #555; /* Slightly brighter border on hover */
}

/* NFT Grid: Always 5 cards per row */
.nft-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Fixed 5 cards per row */
  gap: 20px; /* Adds spacing between cards */
}

@media (max-width: 768px) {
  .nft-grid {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on small screens */
  }

  .controls {
    flex-direction: column; /* Stack controls vertically on smaller screens */
  }

  .search-bar,
  .sort-dropdown,
  .filter-dropdown {
    flex: none; /* Prevent stretching */
    width: 100%; /* Full width for easier input on mobile */
  }

  .nft-details h2 {
    font-size: 14px; /* Reduce text size for mobile */
  }
}

/* NFT Card */
.nft-card {
  background: #1e1e1e; /* Dark background for cards */
  border: 1px solid #333; /* Subtle border for cards */
  color: #f1f1f1; /* Light text color */
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensure content doesn't overflow the card */
  word-wrap: break-word; /* Wrap long words inside cards */
}

.nft-card:hover {
  transform: translateY(-5px);
}

.nft-image {
  width: 100%;
  height: 200px; /* Fixed height for consistent card design */
  object-fit: cover; /* Ensures the image fills the space without distortion */
  border-bottom: 1px solid #333; /* Separator between image and text */
}

.nft-details {
  padding: 10px;
  text-align: left;
  background-color: #1e1e1e; /* Matches card background */
}

/* Name Wrapping */
.nft-name {
  font-size: 14px;
  margin: 5px 0;
  word-wrap: break-word; /* Wrap long words */
  overflow-wrap: break-word;
}

/* Metadata List */
.metadata-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #ddd; /* Subtle text color */
}

.metadata-item {
  margin-bottom: 5px;
}

/* Seed Hyperlink */
.seed-link {
  color: #1e90ff; /* Bright blue link color */
  text-decoration: none;
  font-weight: bold;
}

.seed-link:hover {
  color: #63b3ff; /* Slightly brighter on hover */
  text-decoration: underline;
}

/* Overlay */
.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-overlay img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s;
}

.image-overlay img:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  cursor: pointer;
}
