/* General container styling */
.header-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(145deg, #1a1a1d, #2c2c34);
  border-radius: 0; /* No rounded corners for full-width */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  color: #e0e0e0;
}

/* Loading state */
.loading {
  font-size: 18px;
  color: #888;
  text-align: center;
}

/* Token image */
.token-image-container {
  flex: 0 0 80px;
  margin-right: 20px;
}

.token-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Token details */
.token-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.token-name {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}

.token-address {
  font-size: 14px;
  color: #b0b0b0;
  cursor: pointer;
  text-decoration: underline;
}

.copy-button {
  background: none;
  border: none;
  font-size: 16px;
  color: #007BFF;
  margin-left: 10px;
  cursor: pointer;
}

/* Token stats */
.token-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.stat-item {
  font-size: 14px;
  color: #cccccc;
  white-space: nowrap;
}

/* Social links */
.social-links {
  display: flex;
  gap: 15px;
  margin-left: auto;
  margin-top: 10px;
}

.social-link {
  text-decoration: none;
  color: #007BFF;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s;
}

.social-link:hover {
  color: #409eff;
}

/* Responsive styling */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .token-image-container {
    margin-bottom: 15px;
  }

  .token-stats {
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }

  .social-links {
    margin-left: 0;
    margin-top: 15px;
  }
}

.pumpfun-link {
  text-decoration: none;
  color: #ffd700; /* Bright gold for distinction */
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s;
}

.pumpfun-link:hover {
  color: #ffc107; /* Slightly brighter gold on hover */
}
