/* App.css */

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  color: #fff;
  transition: all 0.3s ease;
  background-color: black;
}

/* Search Bar */
.search-bar {
  padding: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Filters */
.filters {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.filters select {
  padding: 8px;
  background-color: #333;
  border: 1px solid #444;
  color: #fff;
  border-radius: 5px;
}

/* NFT Grid */
.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* NFT Card */
.nft-card {
  background-color: #222;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nft-card:hover {
  transform: scale(1.05);
}

.nft-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.nft-card h2 {
  margin-top: 10px;
  font-size: 18px;
}

.txn-link {
  color: #56ccf2;
  text-decoration: none;
  font-size: 14px;
}

/* Loading Message */
.loading {
  text-align: center;
  font-size: 16px;
  color: #bbb;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  position: relative;
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
}

.overlay-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }
}

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.5rem;
  color: #666;
}

.infinite-scroll-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #666;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.infinite-scroll-loader p {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
}
